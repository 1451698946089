import React, { useEffect, useState } from "react";

import Papirus from "../images/bg.png";
import PapirusCropped from "../images/bg_cropped.png";
import Medal1 from "../images/medal1.png";
import Medal2 from "../images/medal3.png";
import Medal3 from "../images/medal2.png";
import Image11 from "../images/1_1.jpeg";
import Image12 from "../images/1_2.jpeg";
import Image13 from "../images/1_3.jpeg";
import Image14 from "../images/1_4.jpeg";
import Image21 from "../images/2_1.jpeg";
import Image22 from "../images/2_2.jpeg";
import Image23 from "../images/2_3.jpeg";
import Image24 from "../images/2_4.jpeg";
import Image25 from "../images/2_5.jpeg";
import Image26 from "../images/2_6.jpeg";
import Image27 from "../images/2_7.jpeg";
import Image28 from "../images/2_8.jpeg";
import Image31 from "../images/3_1.jpeg";
import Image33 from "../images/3_3.jpeg";
import Image41 from "../images/4_1.jpeg";
import Image42 from "../images/4_2.jpeg";
import Image43 from "../images/4_3.jpeg";
import Image44 from "../images/4_4.jpeg";
import Image45 from "../images/4_5.jpeg";
import Image46 from "../images/4_6.jpeg";
import Image47 from "../images/4_7.jpeg";
import Image48 from "../images/4_8.jpeg";

const currentCroniqueDays = [
  {
    title: "Nowy przybysz w wiosce.",
    date: "04.06.2024",
    content: [
      {
        title: "Wiesci o Wojowniku UZUMAKI",
        text: "Dochodziły mnie słuchy o potężnym wojowniku imieniem UZUMAKI, który grasował w naszej wiosce, siejąc strach i niepokój wśród mieszkańców. Jego imię szeptano z lękiem, a jego cień przemykał przez ulice jak mroczna legenda. Muszę stawić mu czoła!",
      },
      {
        title: "Misja Przywrocenia Spokoju",
        text: "Serce biło mi mocno, a każda chwila wypełniona była determinacją. Wioska, choć na pozór spokojna, była pełna niepokoju i oczekiwań. Ludzie patrzyli na mnie z nadzieją, jakby wiedzieli, że jestem ich ostatnią nadzieją.",
      },
      {
        title: "Droga do Mistrzostwa",
        text: "Aby zmierzyć się z takim przeciwnikiem, musiałem stać się silniejszy. W pierwszej kolejności skupiłem się na osiągnięciu 80 poziomu. Wędrowałem przez niebezpieczne krainy, walczyłem z dzikimi bestiami i odkrywałem tajemne zaklęcia. Każde zwycięstwo przybliżało mnie do mojego celu, a każde starcie umacniało moje umiejętności.",
      },
      {
        title: "Mistrz Czarnej Magii",
        text: "Po osiągnięciu nowego poziomu, zabrałem się za doskonalenie moich umiejętności. Skupienie i dyscyplina pozwoliły mi osiągnąć poziom P1. Moja moc rosła z każdym dniem.",
      },
      {
        title: "Rozwoj Wiernych Towarzyszy",
        text: "Nie zapomniałem również o moich pomocnikach. Razem trenowaliśmy dzień i noc, a jego trzy umiejętności – Błogosławieństwo, Odbicie oraz Zwiększenie Ataku – osiągnęły poziom P1. Wiedziałem, że razem jesteśmy niepokonani. Dodatkowo, trzy umiejętności mojego ukochanego zwierzaka osiągnęły poziom P1, a jego poziom wzrósł do 64.",
      },
      {
        title: "Misje w Miescie",
        text: "Po osiągnięciu mistrzostwa w umiejętnościach, postanowiłem zająć się zadaniami w mieście. Wielu mieszkańców potrzebowało mojej pomocy, więc skupiłem się na wykonaniu wszystkich zadań kolekcjonerskich, biologa, czystek oraz łowów. Dzięki temu mogłem zdobyć cenne artefakty i zwiększyć swoje możliwości. Szczególne podziękowania należą się @Miszoł za dostarczenie odpowiednich szkatułek – Twoja pomoc była nieoceniona!",
      },
    ],
    images: [Image11, Image12, Image13, Image14],
  },
  {
    title: "Zdrada i Nadzieja",
    date: "05.06.2024",
    content: [
      {
        title: "Zdrada Zalozyciela",
        text: "Dziś odkryłem, że założyciel naszego miasta (@Mohito) brutalnie mnie oszukał. Mimo że przybyłem tu, by sprowadzić pokój, zostałem pominięty w rozdawaniu przepotężnych narzędzi do zwalczenia Uzumakiego (@Dyrektor)  Straciłem mnóstwo cennych bonusów procentowych na potwory. Mimo tej zdrady, moja determinacja nie osłabła. Wiem, że muszę kontynuować swoją misję.",
      },
      {
        title: "Pomoc Potrzebującym",
        text: "Cały dzień spędziłem na wykonywaniu misji. Wioska była pełna osób potrzebujących mojej pomocy. Każde zadanie, choć czasochłonne, przybliżało mnie do celu i umacniało moje miejsce w społeczności. Pomoc innym była dla mnie równie ważna, jak walka z Uzumakim.",
      },
      {
        title: "Przygotowania do Walki z Graczami",
        text: "W międzyczasie zaopatrzyłem się w pierwsze przedmioty do walki z innymi graczami. Nowe uzbrojenie i magiczne artefakty zwiększyły moje możliwości bojowe, przygotowując mnie do nadchodzących starć. Każdy zakup i każde przygotowanie było krokiem w stronę ostatecznego zwycięstwa.",
      },
      {
        title: "Warta Kapitana",
        text: "Na koniec dnia przyjąłem od kapitana 12-godzinną wartę. Straż przyboczna jest kluczowa dla bezpieczeństwa wioski, a moja obecność zapewniała mieszkańcom spokój. Po długim dniu pełnym emocji, wypełnionym zdradą, pomocą i przygotowaniami, zasłużyłem na odpoczynek.",
      },
    ],
    images: [
      Image21,
      Image22,
      Image23,
      Image24,
      Image25,
      Image26,
      Image27,
      Image28,
    ],
  },
  {
    title: "Podstep, Alchemia i Nowe Wyzwania.",
    date: "06.06.2024",
    content: [
      {
        title: "Podstep na Terenach Gildyjnych",
        text: "Dziś postanowiłem podjąć ryzykowną próbę nawiązania relacji z UZUMAKIM, myśląc, że nie zna mojej prawdziwej tożsamości. Udałem się na Tereny Gildyjne, gdzie miałem nadzieję zdobyć jego zaufanie. Spotkanie odbyło się w cieniu starych dębów, gdzie atmosfera była pełna napięcia. Po krótkiej rozmowie, UZUMAKI zaproponował, że weźmie mój pasek i w geście przyjaźni zmieni mi bonus 5% przełamania broni na 10%, aby uczynić mnie silniejszym. Bez wahania przekazałem mu swój pasek, wierząc w jego intencje. Jednak to był podstęp! UZUMAKI musiał dowiedzieć się, że przybyłem do tego miasta, by go zniszczyć. Oddał mi totalnie zniszczony, poszczępiony pasek z bonusem 1% bloku, co znacznie osłabiło moje możliwości. Stałem tam oszołomiony, patrząc na zniszczony artefakt, podczas gdy UZUMAKI zniknął w mroku lasu, pozostawiając za sobą echo złowrogiego śmiechu. Teraz już wiem, że muszę trzymać się od niego z daleka!",
      },
      {
        title: "Progres w Mocy",
        text: "Po zdradzie UZUMAKI, zrozumiałem, że czas na podstępne manewry minął. Skoncentrowałem się na wzmocnieniu mojej postaci, przygotowując się na bezpośrednie starcie. Przez cały dzień walczyłem zaciekle, aż dobiłem 85 poziom, odblokowując nowe misje. Każde starcie i każda zdobyta nagroda przybliżały mnie do osiągnięcia celu. Podczas jednej z misji spotkałem tajemniczą postać, która zdradziła mi sekrety ukrytego skarbu na końcu Złotej Doliny. Skończyłem wszystkie misje biologa, kolekcjonera oraz pokonałem metiny i bossy do 85 poziomu. Moja determinacja była silniejsza niż kiedykolwiek, a moje umiejętności rosły z każdą chwilą. Wierzyłem, że zdobyta wiedza i doświadczenie uczynią mnie godnym przeciwnikiem dla UZUMAKI.",
      },
      {
        title: "Tajemnica Alchemii",
        text: "Podczas moich wędrówek po mieście usłyszałem od Starego Mędrca, że każdy prawdziwy wojownik potrzebuje alchemii. To tajna moc, której musiałem się nauczyć! Zaopatrzyłem się w cały zestaw znakomitej alchemii, gotowy do zgłębiania jej tajemnic. Wiedziałem, że ta moc może dać mi przewagę w walce z UZUMAKI i uczynić mnie jeszcze potężniejszym. Stary Mędrzec opowiedział mi legendę o alchemiku, który stworzył eliksir nieśmiertelności, ale zapłacił za to najwyższą cenę. Te historie, pełne tajemnic i magii, inspirowały mnie do dalszego poszukiwania wiedzy. Wieczorami, przy blasku księżyca, studiowałem starożytne manuskrypty, starając się zrozumieć każdy składnik i formułę.",
      },
      {
        title: "Awans na Nowy Poziom",
        text: "Odblokowałem również możliwość awansowania na stopień 1 mojej postaci. To oznaczało nowe wyzwania i jeszcze większe możliwości. W następnych dniach muszę się za to wziąć, aby w pełni wykorzystać swój potencjał i przygotować się na ostateczne starcie. Spotkałem starego rycerza, który opowiedział mi o próbach i wyzwaniach, które muszę przejść, aby zdobyć ten awans. Każda próba była bardziej wymagająca od poprzedniej, ale wiedziałem, że to jest moja droga do stania się legendą. Przede mną pojawiły się wizje smoków, starożytnych ruin i zapomnianych krain, które musiałem odkryć, by zdobyć nową moc.",
      },
      {
        title: "Nowa Forma Kroniki",
        text: "Opracowałem nową formę mojej kroniki, którą teraz właśnie czytasz. Dzięki niej mogę szczegółowo dokumentować moje przygody, osiągnięcia i niebezpieczeństwa, które napotykam na swojej drodze. Każda strona tej kroniki jest świadectwem mojej determinacji i poświęcenia w walce przeciwko UZUMAKI. Zachęcam każdego z was do przesyłania swoich propozycji i wsparcia, które możecie wysłać mi listem na adres Discord. Razem możemy stworzyć historię pełną heroicznych czynów i niesamowitych przygód, które będą inspirować przyszłe pokolenia.",
      },
      {
        title: "Kuznia Mocy",
        text: "Pracowałem nad stworzeniem idealnej broni do walki z UZUMAKI. W kuźni, wśród żaru i dymu, wydałem ponad milion zmianek, by stworzyć idealny miecz. Miecz, który będzie miał w sobie 12 inteligencji, bonus na ludzi, bonus na szansę na krytyczne uderzenie oraz zręczność. Każda próba była pełna nadziei i oczekiwania, każda nieudana próba była kolejną lekcją. Niestety, mimo wszystkich wysiłków, nie udało się. Ostatecznie stworzyłem miecz, który nie spełniał moich oczekiwań. Zamiast idealnej broni, otrzymałem zaledwie pospolity oręż, który nie zdołałby pokonać tak potężnego przeciwnika. Mimo tego niepowodzenia, nie tracę nadziei. Wiem, że droga do doskonałości jest długa i pełna wyzwań, ale jestem zdeterminowany, by znaleźć sposób na stworzenie broni, która pokona UZUMAKI.",
      },
    ],
    images: [Image31, Image33],
  },
  {
    title: "Nowa Kronika, Kamienie Krwi i Wielkie Wyprawy",
    date: "07.06.2024",
    content: [
      {
        title: "Echo Kroniki",
        text: "Moja nowa forma kroniki odbiła się szerokim echem po mieście. Ludzie zaczęli mnie rozpoznawać na ulicach, podawać rękę, kłaniać się oraz zdejmować kapelusze. Chociaż początkowo cieszyło mnie uznanie, zastanawiałem się, czy był to dobry krok. W blasku uznania poczułem ciężar odpowiedzialności, który narastał z każdym kolejnym dniem. Dlatego postanowiłem na dłuższą chwilę opuścić miasto i wyruszyć na długie wyprawy w celu zdobycia niezbędnego doświadczenia. Wędrując przez odludne miejsca, mogłem skupić się na moich treningach i przygotowaniach do przyszłych wyzwań.",
      },
      {
        title: "Krwawe Kamienie",
        text: "Stary Mędrzec, mój mentor i przewodnik, opowiedział mi o bardzo potężnych, krwistych kamieniach, które są niezbędne do osiągnięcia stopnia pierwszego. Kilkukrotnie odbywałem przejażdżki po ognistych ziemiach, badając różne kamienie, a następnie ochładzając je w lodowych górach. Każdy kamień był unikalny i pełen tajemniczej mocy, którą musiałem zrozumieć. Po długich podróżach przyprowadzałem je z powrotem do mędrca do badania. Dzięki jego mądrości i moim wysiłkom zdobyłem już wystarczającą ilość, by osiągnąć stopień pierwszy. Te kamienie były kluczem do mojej przyszłej potęgi.",
      },
      {
        title: "Bitwa o Nefrytową Zatokę",
        text: "Podczas naszych badań, Stary Mędrzec długo opowiadał mi o pewnej Nefrytowej Zatoce, gdzie spędzał swoje dzieciństwo wraz z ojcem. Spędzał tam wakacje łowiąc ryby i plażując z rodziną. Jednak od kilkunastu lat Zatokę przejęły olbrzymie, przerażające, oślizgłe stwory. Postanowiłem udać się tam i zrobić porządki. Zabijając stwory i oczyszczając Zatokę, nie tylko przywróciłem spokój temu pięknemu miejscu, ale także udało mi się osiągnąć 95 poziom postaci, co odblokowało mi całą masę nowych misji. Widząc Zatokę wolną od potworów, czułem dumę i satysfakcję z wykonanej pracy.",
      },
      {
        title: "Wieża Szkieletów",
        text: "Podczas mojej wędrówki po mieście, usłyszałem rozmowę dwóch wojowników o miejscu zwanym Wieżą Szkieletów, na którą można odbyć wyprawę od 95 poziomu. Idealnie się składało. Moim celem stało się kilkukrotne udanie się na wieżę, pełne jej oczyszczenie oraz osiągnięcie 99 poziomu, co odblokuje mój pełny potencjał. Wieża Szkieletów była pełna mrocznych tajemnic i potężnych przeciwników, ale wiedziałem, że z każdym krokiem zbliżam się do ostatecznego celu. Każda wyprawa była kolejnym krokiem na drodze do mojego przeznaczenia.",
      },
      {
        title: "Przygotowania na Cyrk",
        text: "Usłyszałem również, że pod koniec miesiąca do miasta przyjeżdża Cyrk! Uwielbiam Cyrki, kojarzą mi się z beztroskim dzieciństwem, watą cukrową i zwierzętami. Postanowiłem przygotować odpowiednie ubrania i zakupiłem całą biżuterię z Kryształu Duszy oraz tarczę, pas i buty. Będę mocno szlifował mój ubiór, aby być w 110% gotowy na przyjazd cyrku. Mam wrażenie, że przyjdę na niejeden spektakl! Przygotowania były nie tylko fizyczne, ale i mentalne - chciałem być gotowy na każdą niespodziankę, którą Cyrk mógł przynieść.",
      },
      {
        title: "Ostatnia Nadzieja",
        text: "Przede mną jeszcze wiele wyzwań, ale z waszym wsparciem wiem, że żadne zło nie będzie w stanie nas pokonać.",
      },
    ],
    images: [
      Image47,
      Image48,
      Image41,
      Image42,
      Image43,
      Image44,
      Image45,
      Image46,
    ],
  },
];

const Cronique = () => {
  const [currentDay, setCurrentDay] = useState<number | null>(null);
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement>();

  const playLector = (i: number) => {
    if (currentAudio) {
      currentAudio.pause();
    }
    console.log(i);
    const audio = new Audio(`/${i}.mp3`);
    audio.loop = false;
    audio.volume = 0.7;

    audio.play();

    setCurrentAudio(audio);
  };

  useEffect(() => {
    if (currentDay !== null) {
      playLector(currentDay);
    } else {
      if (currentAudio) {
        currentAudio.pause();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDay]);

  return (
    <section className="py-0" style={{ top: "-5vh" }}>
      <div className="container row mx-auto papirus-container align-items-start">
        <div className="col-lg-3 col-12 px-16 medal-container">
          <img src={Medal1} className="medal one" alt="medal" />
          <img src={Papirus} className="papirus" alt="background" />
          <div className="papirus-content one">
            <h2 className="w-100 text-center fs-40">Cel Kroniki</h2>
            <p className="long-text">
              Kroniki XxBartekBMxX mają na celu dokumentowanie heroicznych
              czynów BartkaBM, potężnego wojownika władającego Czarną Magią,
              który przysiągł uratować naszą wioskę. BartekBM, znany ze swojej
              siły i odwagi, wyrusza na niebezpieczne misje, by stawić czoła
              mrocznym siłom, które zagrażają naszemu światu. Jego determinacja
              i niezłomna wola są inspiracją dla wszystkich mieszkańców. Każde
              zwycięstwo i każda porażka zostaną tutaj zapisane, aby przyszłe
              pokolenia mogły poznać historię jego walki. Kronika ta ma nie
              tylko służyć jako zapis wydarzeń, ale także jako źródło nadziei i
              motywacji dla wszystkich, którzy wierzą w triumf dobra nad złem.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-12 px-lg-16 my-32 my-lg-0 medal-container">
          <img src={Medal2} className="medal two" alt="medal" />
          <img src={Papirus} className="papirus" alt="background" />
          <div className="papirus-content two px-40">
            {currentDay === null ? (
              <>
                <h2 className="w-100 text-center fs-40">Kroniki BartkaBM</h2>
                <div className="cronique-days">
                  {currentCroniqueDays.map((day, i) => (
                    <div
                      key={`cronique-days-${i}`}
                      className="cronique-day"
                      onClick={() => setCurrentDay(i)}
                    >
                      <h3>
                        {day.date} - {day.title}
                      </h3>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="single-day">
                <span
                  onClick={() => setCurrentDay(null)}
                  className="back-button-text"
                >
                  {"< Powrót Do Kroniki"}
                </span>
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                  <span className="date">
                    {currentCroniqueDays[currentDay].date}
                  </span>
                  <h3 className="w-100 text-center">
                    {currentCroniqueDays[currentDay].title}
                  </h3>
                </div>
                {currentCroniqueDays[currentDay].content.map((content, i) => (
                  <div
                    className="single-day-content"
                    key={`single-day-content${i}`}
                  >
                    <h4>
                      Rozdział {i + 1}.<br /> {content.title}
                    </h4>
                    <p> {content.text}</p>
                  </div>
                ))}
                <div className="d-flex flex-wrap row">
                  {currentCroniqueDays[currentDay].images.map((img) => (
                    <img
                      key={img}
                      className="col-lg-6 col-12 mb-12 mb-lg-4"
                      src={img}
                      alt="ScreenShots"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-3 py-16 py-lg-0 col-12 px-16 medal-container">
          <img src={Medal3} className="medal three" alt="medal" />
          <img src={Papirus} className="papirus" alt="background" />
          <div className="papirus-content three">
            <h2 className="w-100 text-center">UZUMAKI</h2>
            <p className="long-text">
              to imię, które budzi strach w sercach mieszkańców naszego miasta.
              Pochodzenie tego mrocznego wojownika jest owiane tajemnicą, ale
              jego brutalne czyny mówią same za siebie. UZUMAKI terroryzuje
              miasto, siejąc chaos i zniszczenie. Największym ciosem było
              zamordowanie starego Burmistrza, który chronił nas swoją
              mądrością. Jego śmierć pozostawiła miasto w stanie niepewności i
              lęku. Na murach ratusza pojawił się krwawy wir – symbol UZUMAKI.
              Moim celem, jako XxBartekBMxX, jest zgładzić tego potwora i
              przywrócić spokój. UZUMAKI nie tylko zagraża życiu mieszkańców,
              ale niszczy ich ducha. Jego terror musi się skończyć, a ja jestem
              zdeterminowany, aby to osiągnąć.
            </p>
          </div>
        </div>
      </div>
      <h1
        className="title text-center"
        style={{
          color: "#d8af84",
          margin: "40px 0 100px",
        }}
      >
        OTRZYMALES LIST!
      </h1>
      <div className="container row mx-auto papirus-container align-items-start">
        <div className="col-lg-1 medal-container" />
        <div className="col-lg-10 col-12 medal-container">
          <img
            src="https://eny.pl/img/logo.8dd7890d.png"
            className="medal three"
            alt="medal"
          />
          <img src={PapirusCropped} className="papirus" alt="background" />
          <div className="papirus-content three">
            <h2 className="w-100 text-center">
              Oficjalne Zaproszenie Do Bitwy
            </h2>
            <p className="px-lg-80 mt-40">
              Drodzy Bohaterowie,
              <br />
              <br />
              Nadszedł czas, by połączyć nasze siły i stanąć do walki przeciwko
              UZUMAKI, który sieje strach i zniszczenie w naszej krainie. Wasza
              odwaga i determinacja są kluczem do zwycięstwa, a nasza wspólna
              siła może przywrócić pokój i bezpieczeństwo. Każdy z was ma w
              sobie potencjał, by stać się bohaterem, którego nasza wioska
              potrzebuje w tych mrocznych czasach. Niech wasze serca płoną żądzą
              walki, a dusze niech będą gotowe na największe wyzwania. Tylko
              razem, jako zjednoczona siła, możemy stawić czoła temu złowrogiemu
              przeciwnikowi i ochronić naszą krainę przed jego destrukcyjnymi
              działaniami.
              <br />
              <br />
              Dołączcie do mnie na serwerze Eny2, gdzie razem stworzymy potężną
              armię. Twórzcie silne postacie, doskonalcie swoje umiejętności i
              wspólnie stawmy czoła mrocznym siłom. Nasza wspólna determinacja i
              zjednoczenie mogą przechylić szalę zwycięstwa na naszą stronę.
              Wybierzcie swoją drogę jako Sura, Szaman, Wojownik lub Ninja.
              Każda z tych klas ma unikalne umiejętności i zdolności, które mogą
              przyczynić się do naszej wspólnej sprawy. Każdy nowy wojownik, mag
              czy łucznik przybliża nas do triumfu nad złem, które dręczy naszą
              krainę. Wasze zaangażowanie i wola walki są nieocenione w tej
              epickiej batalii.
              <br />
              <br />
              Nasza wioska potrzebuje waszego wsparcia bardziej niż
              kiedykolwiek. UZUMAKI to nie tylko zagrożenie dla życia, ale i dla
              ducha naszego społeczeństwa. Jego brutalne ataki i terror nie mogą
              pozostać bez odpowiedzi. Razem, jako zjednoczona armia, możemy
              pokonać tę mroczną siłę i przywrócić harmonię naszej krainie.
              Każdy z was wnosi unikalne umiejętności i talenty, które są
              niezbędne do zwycięstwa. Jako Sura, wasza magia i miecz mogą siać
              zniszczenie w szeregach wroga. Jako Szaman, wasze wsparcie i
              leczenie będą nieocenione dla towarzyszy. Wojownik swoją siłą i
              wytrzymałością stanie na pierwszej linii frontu, a Ninja swoimi
              zręcznymi atakami z cienia zada decydujące ciosy. Każdy z was jest
              ważnym elementem naszej wspólnej walki.
              <br />
              <br />
              Wasza obecność na polu bitwy jest nieoceniona. Im więcej nas
              będzie, tym silniejsi się staniemy. Każdy z was wnosi unikalne
              umiejętności i talenty, które są niezbędne do zwycięstwa. Wierzę,
              że razem jesteśmy w stanie pokonać UZUMAKI i przywrócić pokój
              naszej wiosce. Niech wasza odwaga będzie światłem prowadzącym nas
              do zwycięstwa. Dołączcie do nas już dziś, rozpocznijcie swoją
              przygodę na Eny2 i stańcie się częścią tej epickiej bitwy.
              <br />
              <br />
              Razem jesteśmy niepokonani!
              <br />
              <br />Z pozdrowieniami, <br />
              XxBartekBMxX <br />
              <button
                onClick={() =>
                  window.open(
                    "https://eny.pl/register.html?ref=programistanafroncie",
                    "_blank"
                  )
                }
              >
                PRZYJMUJE ZAPROSZENIE!
              </button>
            </p>
          </div>
        </div>
        <div className="col-1 medal-container" />
      </div>
    </section>
  );
};

export default Cronique;
