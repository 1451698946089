import React from "react";

import Bg from "../images/hero.jpg";
import Logo from "../images/logo.png";

const HeroSection = () => (
  <section className="hero-section d-flex align-items-center justify-content-center">
    <img alt="BG" src={Bg} className="hero-section-bg" />
    <div className="logo d-flex align-items-center">
      <div className="name">KRONIKA</div>
      <img src={Logo} alt="logo" />
      <div className="name">BartekBM</div>
    </div>
    <div className="container">
      <div className="row flex-column-reverse flex-lg-row"></div>
    </div>
  </section>
);

export default HeroSection;
