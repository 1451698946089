import React, { useState, useEffect } from "react";
import HeroSection from "../components/HeroSection.component";
import Cronique from "../components/Cronique.component";

const Home = () => {
  return (
    <main id="home">
      <HeroSection />
      <Cronique />
    </main>
  );
};

export default Home;
