import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./scss/main.scss";
import Home from "./views/Home.view";

const App = () => {
  useEffect(() => {
    const audio = new Audio("/background-music.mp3");
    audio.loop = true;
    audio.volume = 0.07; // Ustawienie głośności na 20%

    const playAudio = () => {
      if (audio.paused) {
        audio.play().catch((error) => {
          console.error("Error playing the audio:", error);
        });
      }
    };

    window.addEventListener("scroll", playAudio);
    window.addEventListener("click", playAudio);

    return () => {
      window.removeEventListener("scroll", playAudio);
    };
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
